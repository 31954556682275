import React from "react";
import { Button } from "rebass";

function CustomButton(props) {
  return (
    <Button
      className="unselectable"
      sx={
        props.type === "primary"
          ? {
              outlineWidth: "0px",
              fontFamily: "Lato",
              fontWeight: "700",
              borderRadius: "0.3em",
              "&:hover": { bg: "specialRed", color: "background" },
              border: "1px solid",
              borderColor: "specialRed",
              bg: "background",
              color: "specialRed",
              ...props.sy,
            }
          : props.type === "stats"
          ? {
              outlineWidth: "0px",
              fontFamily: "Lato",
              fontWeight: "700",
              "&:hover": { color: "white", bg: "darkgray" },
              border: "1px solid",
              borderColor: "darkgray",
              bg: "transparent",
              color: "darkgray",
              height: "30px",
              alignSelf: "center",
              py: "1px",
              ...props.sy,
            }
          : {
              fontSize: "20px",
              outlineWidth: "0px",
              fontFamily: "Lato",
              fontWeight: "700",
              borderRadius: "0.3em",
              border: props.passed ? "1px solid" : "1px solid",
              borderColor: props.passed ? "darkgray" : "background",
              "&:hover": props.passed
                ? { bg: "darkgray", color: "background" }
                : { bg: "background", color: "specialRed" },
              color: props.passed ? "black" : "white",
              px: "40px",
              mx: "10px",
            }
      }
      {...props}
    >
      {props.content}
    </Button>
  );
}

export default CustomButton;
