import React, { useState } from "react";
import { Flex, Box, Button } from "rebass";
import { Label, Checkbox } from "@rebass/forms";
import eventEmitter from "../events";

const _radioSelector = [
  { type: 3 },
  { type: 5 },
  { type: 10 },
  { type: 15 },
  { type: 20 },
  { type: 30 },
  { type: 60 },
];

const _radioSelectorWords = [
  { type: 150 },
  { type: 250 },
  { type: 500 },
  { type: 750 },
  { type: 1667 },
];

/*
to check if minutes or words are selected see this.state.color true=minutes false =words
itemName for the minutes or words number
*/
function Note() {
  const [display, setDispaly] = useState(false);
  const [color, setColor] = useState(true);
  //const [active, setActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);
  //const [fromBtn, setFromBtn] = useState(false);

  const _Onclick = () => {
    setDispaly(!display);
    setSelectedIndex("");
  };

  const _OnclickMinWords = () => {
    setColor(!color);
    setSelectedIndex(1);

    //initializing values
    let params = {
      color: !color,
      item: color ? 250 : 5,
    };

    eventEmitter.emit("fromNote", params);
  };
  const _OnclickMinutes = () => {
    return color ? null : _OnclickMinWords();
  };
  const _OnclickWords = () => {
    return color ? _OnclickMinWords() : null;
  };
  const _ColorMinutes = () => {
    return color ? "white" : "specialRed";
  };
  const _ColorWords = () => {
    return color ? "specialRed" : "white";
  };
  const _MapCircleRadioButtons = () => {
    return color
      ? _radioSelector.map((item, index) => (
          <Button
            data-test-id={`minutes-${item.type}`}
            className="unselectable"
            variant="circular"
            onClick={() => {
              setSelectedIndex(index);

              let params = {
                color: color,
                item: item.type,
              };

              eventEmitter.emit("fromNote", params);
            }}
            color={selectedIndex === index ? "specialRed" : "darkText"}
            sx={{
              border: [selectedIndex === index ? "1.0px solid" : "0px solid"],
              borderColor: [
                selectedIndex === index ? "specailRed" : "background",
              ],
            }}
          >
            {item.type}
          </Button>
        ))
      : _radioSelectorWords.map((item, index) => (
          <Button
            data-test-id={`words-${item.type}`}
            className="unselectable"
            variant="circular"
            onClick={() => {
              setSelectedIndex(index);

              let params = {
                color: color,
                item: item.type,
              };

              eventEmitter.emit("fromNote", params);
            }}
            color={selectedIndex === index ? "specialRed" : "darkText"}
            sx={{
              border: [selectedIndex === index ? "1.0px solid" : "0px solid"],
              borderColor: [
                selectedIndex === index ? "specailRed" : "background",
              ],
            }}
          >
            {item.type}
          </Button>
        ));
  };

  return (
    <Box height="115px" pt="0" pb="0.6em" mb="1.0em" mt="0">
      <Box
        display={"none"}
        onClick={_Onclick}
        sx={{
          position: "relative",
          "&:hover .penIcon": { color: "background" },
        }}
      >
        <Flex
          width={1}
          justifyContent="center"
          sx={{ position: "absolute", top: "89%", left: "0" }}
        >
          <Box
            bg="background"
            width="1.3em"
            height="1.3em"
            sx={{ transform: "rotate(45deg)" }}
          ></Box>
        </Flex>

        <Flex
          flexDirection="row"
          fontSize="0.65em"
          color="textColor"
          bg="background"
          py="0.5em"
          px="0.6em"
          width={1}
          justifyContent="center"
          borderRadius="0.5em"
          sx={{
            position: "absolute",
            top: "78%",
            left: "0",
            borderRadius: "0.2em",
          }}
        >
          <Box>Session length:</Box>
          <Box pl="0.2em" sx={{}}>
            <Button pr="15px" variant="secondary">
              5 minutes
            </Button>
          </Box>
        </Flex>
        <Box
          className="penIcon"
          color="#c2bebe"
          sx={{ position: "absolute", top: "80%", right: "6px" }}
        >
          {/* <Icon.PenTool height="0.8em" /> */}
        </Box>
      </Box>

      {/* The click screen */}

      <Box
        bg="background"
        width="300px"
        height="115px"
        fontSize="0.75em"
        display={"block"}
        sx={{ position: "relative", textAlign: "center" }}
      >
        <Box p="0.5em" color="darkText" mb={3}>
          <Button
            data-test-id="select-minutes"
            color={_ColorMinutes()}
            p=".1em"
            variant="tertiary"
            bg={color ? "specialRed" : "white"}
            onClick={_OnclickMinutes}
          >
            Minutes
          </Button>
          <Button
            data-test-id="select-words"
            onClick={_OnclickWords}
            color={_ColorWords()}
            bg={color ? "white" : "specialRed"}
            p=".1em"
            variant="tertiary"
          >
            Words
          </Button>
        </Box>
        <Flex flexDirection="row" justifyContent="center">
          {_MapCircleRadioButtons()}
        </Flex>

        <Label
          color="background"
          mt="1em"
          alignItems="center"
          justifyContent="center"
        >
          <Checkbox
            id="remember"
            name="remember"
            color="background"
            className="unselectable"
            width="1.5em"
          />
          Hardcore mode
        </Label>

        <Flex
          width={1}
          justifyContent="center"
          sx={{ position: "absolute", top: "94%", left: "0" }}
        >
          <Box
            bg="background"
            width="1.3em"
            height="1.3em"
            sx={{ transform: "rotate(45deg)" }}
          ></Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default Note;
