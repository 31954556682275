import React, { useState, useEffect } from "react";
import { Flex, Text } from "rebass";
import CustomButton from "./components/Button";
import { ThemeProvider } from "emotion-theming";
import Theme from "./theme/Theme";
import { getStats, levels } from "./util/challenge";
import CustomText from "./components/CustomText";

export default function () {
  const [passed, setPassed] = useState();
  const [failed, setFailed] = useState();
  const [level, setLevel] = useState();
  const [desp, setDesp] = useState();
  const [hp, setHp] = useState();
  const [stats, setStats] = useState([]);
  const [barLength, setBarLength] = useState(0);

  useEffect(() => {
    let data = getStatArray();
    if (data) {
      let mainData = getStats(data);

      setPassed(mainData.passed);
      setFailed(mainData.failed);
      setLevel(mainData.level.name);
      setDesp(mainData.level.description);
      setHp(mainData.level.hp);
      setStats(data);
      setBarLength(
        ((mainData.level.hp - mainData.level.min) /
          (mainData.level.max - mainData.level.min)) *
          100
      );
    } else {
      setPassed(0);
      setFailed(0);
      setLevel(levels[0].name);
      setDesp(levels[0].description);
      setHp(0);
    }
  }, []);

  const getStatArray = () => {
    if (localStorage.getItem("stats")) {
      let stats = JSON.parse(localStorage.getItem("stats"));
      return stats;
    }

    return undefined;
  };

  const downloadTxtFile = (text) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <ThemeProvider theme={Theme}>
      <Flex
        justifyContent="center"
        flexDirection="row"
        overflowY="scroll"
        height="100vh"
      >
        <Flex
          width={["100vw", "75vw", "50vw"]}
          flexDirection="column"
          height="50%"
          flexShrink="0"
          px="25px"
        >
          <Text
            color="darkText"
            width={1}
            textAlign="center"
            as="span"
            sx={{
              fontSize: ["1em", "1.5em", "2em"],
              fontFamily: "Lato",
              fontWeight: "900",
            }}
          >
            My
            <Text as="span" color="specialRed">
              {" Challenges"}
            </Text>
          </Text>
          <CustomText
            color="darkText"
            width={1}
            py="0px"
            sy={{
              fontSize: ["1.2em", "1.6em", "2.2em"],
              fontWeight: "700",
            }}
            content={level}
          ></CustomText>
          <CustomText
            color="gray"
            width={1}
            py="0px"
            sy={{
              fontSize: ["0.7em", "0.8em", "1em"],
            }}
            content={desp}
          ></CustomText>
          <Flex width={0.8} flexDirection="column" alignSelf="center" mt="20px">
            <Flex width={1} bg="lightgray" sx={{ position: "relative" }}>
              <Flex
                bg="specialRed"
                width={barLength}
                py={["15px", "20px", "25px"]}
              ></Flex>
              <Text
                color="black"
                width={1}
                //bg="specialRed"
                textAlign="end"
                //py="15px"
                //px="75px"
                sx={{
                  fontSize: ["0.8em", "1em", "1.3em"],
                  fontFamily: "Lato",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                  position: "absolute",
                  top: ["9px", "12.5px", "12.5px"],
                  right: "75px",
                }}
              >
                {hp + "  HP"}
              </Text>
            </Flex>
            <Text
              color="black"
              textAlign="center"
              p="2px"
              sx={{
                fontSize: ["0.7em", "0.8em", "1em"],
                fontFamily: "Lato",
                fontWeight: "400",
              }}
            >
              {`You have passed ${passed} and failed ${failed} challenges`}
            </Text>
          </Flex>
          <Text
            color="darkText"
            width={1}
            textAlign="start"
            sx={{
              fontSize: ["1.2em", "1.6em", "2.2em"],
              fontFamily: "Lato",
              fontWeight: "700",
            }}
          >
            Writings
          </Text>
          <Text
            color="gray"
            width={1}
            textAlign="start"
            pb="10px"
            sx={{
              fontSize: ["0.7em", "0.8em", "1em"],
              fontFamily: "Lato",
              fontWeight: "400",
            }}
          >
            All your writings are here
          </Text>
          {stats.map(function (stat, index) {
            return stat.passed ? (
              <Flex flexDirection="column" justifyContent="left">
                <CustomText
                  data-test-id={`prompt-${index}`}
                  title="Prompt: "
                  content={stat.prompt}
                  pb="0px"
                ></CustomText>
                <CustomText
                  data-test-id={`text-${index}`}
                  content={stat.text}
                  overflow="hidden"
                  pt="0px"
                  sy={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                ></CustomText>
                <Flex flexDirection="row">
                  <CustomText
                    footer={true}
                    color="specialRed"
                    width={0.25}
                    content={stat.date}
                  ></CustomText>
                  <CustomText
                    data-test-id={`words-${index}`}
                    footer={true}
                    color="gray"
                    width={0.25}
                    title="Words: "
                    content={stat.words}
                  ></CustomText>
                  <CustomText
                    data-test-id={`mode-${index}`}
                    footer={true}
                    color="gray"
                    width={0.25}
                    title="Mode: "
                    content={stat.duration}
                  ></CustomText>
                  <CustomButton
                    onClick={() => downloadTxtFile(stat.text)}
                    content={"Download"}
                    type="stats"
                    width={0.25}
                    fontSize={["0.7em", "0.8em", "1em"]}
                    mb="20px"
                  ></CustomButton>
                </Flex>
              </Flex>
            ) : (
              ""
            );
          })}
        </Flex>
      </Flex>
    </ThemeProvider>
  );
}
