export const PROMPTS = [
  "He had an hour to get home. If he didn't make it,",
  "The boy hadn't spoken since his twin had gone",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "As the dream faded, she chased it, forlorn",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "He realised the child had seen him and he couldn't let him get away",
  "As he opened the car door, there was a loud",
  "They had to work together, so they would need to",
  "The house wasn't the same to her any more",
  "They couldn't be allowed to fight. He had to think of a way to stop it",
  "They had to work together, so they would need to",
  "Who would have poisoned the old man's dog?",
  "She had no intention of choosing sides until she was sure who was going to win",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "Someone must have seen him, because the police were at his door",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "He'd had a bad day and just needed something to make him feel better",
  "It was the trip of a lifetime, yet",
  "She was carried along by the crowd",
  "He sat her down and held her close before telling her the terrible news",
  "The pen hadn't been worth stealing",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "The woman doting on her children across the room was still recognisable as the bully from her high school",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "The urge to interrupt him before he had finished was overwhelming",
  "Everything about her was a lie",
  "There was something not quite right about the window.",
  "Everything about her was a lie",
  "He hadn't seen the old man since",
  "She was carried along by the crowd",
  "When she opened the door, she wished",
  "The house wasn't the same to her any more",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "It was time. She'd fought against it for so long",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "He could hear everything, but dare not open his eyes",
  "He could hear everything, but dare not open his eyes",
  "The whole family had been cursed since",
  "She looked around quickly, to see if anything had been taken.",
  "There was a strange wailing sound coming from the next room",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "To his horror, he realised he'd been this way before",
  "He kept absolutely still as the footsteps got louder",
  "The kiss reminded him of chemistry lessons in school, when if the right two elements were put together, they'd explode",
  "He'd had a bad day and just needed something to make him feel better",
  "After five years, he just happened to be walking down her street?",
  "There was a strange wailing sound coming from the next room",
  "She suddenly realised she might be alone for the rest of her life",
  "He wanted her job and it would be easy enough to",
  "There was a legend about the well in the garden",
  "To his horror, he realised he'd been this way before",
  "To save his own life, he would have to kill",
  "They couldn't be allowed to fight. He had to think of a way to stop it",
  "When he lifted his head, she barely recognised him for the bruises",
  "Only the very oldest people remembered a time when humans could see in colour",
  "He watched, helpless, as the door closed behind her",
  "She decided to go to her father's grave, to ask his advice",
  "They had been together for twenty-five years",
  "As she searched, her movements were frantic",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "She had made a poor job of hiding the damage",
  "That summer seemed to last forever",
  "When she opened the door, she wished",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "She looked around quickly, to see if anything had been taken.",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "Someone must have seen him, because the police were at his door",
  "She had no intention of choosing sides until she was sure who was going to win",
  "He hadn't seen anything like it in twenty years of teaching",
  "There was sadness in her face as she told him he looked a lot like his father",
  "He waited until her husband was out of the room, then...",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "As soon as she walked in, she felt the tension",
  "She couldn't believe it. Was it really him?",
  "There was a strange wailing sound coming from the next room",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "She had followed the woman for days and at last her patience was paying off",
  "He was excited to find 150 new emails in his inbox",
  "He hadn't seen the old man since",
  "He had never seen so many homeless people in one place",
  "Why had no-one ever mentioned Mum's twin?",
  "The attack was over in seconds",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "She could smell gas even before she opened the door",
  "The whole family had been cursed since",
  "She could smell gas even before she opened the door",
  "With sadness, she realised they needed some time apart",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "She took a deep breath and said to her boss -",
  "When he lifted his head, she barely recognised him for the bruises",
  "The Ferrari stopped and the tinted window opened to reveal",
  "It would only be a fling - she wasn't about to break up the happy home",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "She had missed the last train and there was only one person she",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "They had to work together so they were going to have to learn to get along",
  "He could hear everything, but dare not open his eyes",
  "The attack was over in seconds",
  "She suddenly realised she might be alone for the rest of her life",
  "She turned and nearly fell over the bonnet of his car, which was crawling quietly along the street.",
  "He had enjoyed ten years of being totally irresponsible",
  "As a child, he'd been told dolls were for girls.",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "He hadn't seen anything like it in twenty years of teaching",
  "It was just for one night",
  "They had to work together so they were going to have to learn to get along",
  "They had been together for twenty-five years",
  "The fire was getting closer",
  "He had an hour to get home. If he didn't make it,",
  "She stood out from the crowd because",
  "He hadn't meant to scare the child",
  "The footsteps were moving away",
  "He could hear everything, but dare not open his eyes",
  "As she stopped to catch her breath, she looked back",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "Half the names on the list had already been crossed off.",
  "She'd only given in because she was lonely",
  "The sound of laughter drifted up from the street below, making him feel very alone in this new town",
  "He'd had a bad day and just needed something to make him feel better",
  "It would only be a fling - she wasn't about to break up the happy home",
  "The first Christmas she could remember was also her best Christmas ever.",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "The house wasn't the same to her any more",
  "The attack was over in seconds",
  "As the dream faded, she chased it, forlorn",
  "She'd got him wrong, but that was her loss",
  "It was just for one night",
  "He sat her down and held her close before telling her the terrible news",
  "She had made a poor job of hiding the damage",
  "It would only be a fling - she wasn't about to break up the happy home",
  "He wanted her job, and it would be easy enough to discredit her",
  "She'd got him wrong, but that was her loss",
  "As she stopped to catch her breath, she looked back",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "It would only be a fling - she wasn't about to break up the happy home",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "Without meaning to, she'd arranged two dates for the same evening",
  "Amongst her mother's possessions, she found",
  "They had to work together so they were going to have to learn to get along",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "Half the names on the list had already been crossed off.",
  "There was a legend about the well in the garden",
  "As she searched, her movements were frantic",
  "They had to work together so they were going to have to learn to get along",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "The pink glove lay on the ground, almost covered",
  "There had been many theories about how she had been murdered",
  "When she opened the door, she wished",
  "He hadn't meant to scare the child",
  "There was nothing left of the money except",
  "The house wasn't the same to her any more",
  "The girl shouldn't have been sacked but if he said anything",
  "She could smell gas even before she opened the door",
  "Reluctantly, he handed over the key",
  "To my cheating wife, I leave",
  "The horse came back alone",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "She stood out from the crowd because",
  "It was time. She'd fought against it for so long",
  "She clung on to the piece of driftwood, praying for daylight",
  "She was carried along by the crowd",
  "The text message simply said 'very clever'",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "He had never seen so many homeless people in one place",
  "It would only be a fling - she wasn't about to break up the happy home",
  "He was terrified of small spaces and she knew",
  "The house wasn't the same to her any more",
  "They found his diary under his bed",
  "He had never seen so many homeless people in one place",
  "Why had no-one ever mentioned Mum's twin?",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "She had followed the woman for days and at last her patience was paying off",
  "To save his own life, he would have to kill",
  "The victim had tried to write something as he was dying",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "It would only be a fling - she wasn't about to break up the happy home",
  "The first Christmas she could remember was also her best Christmas ever.",
  "She opened her handbag and tipped the contents onto the floor",
  "The entrance to the tunnel was his only way out",
  "The text message simply said 'very clever'",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "Without meaning to, she'd arranged two dates for the same evening",
  "Amongst her mother's possessions, she found",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "More and more people were refusing to obey the laws of the land",
  "She took a deep breath and said to her boss -",
  "The urge to interrupt him before he had finished was overwhelming",
  "The girl shouldn't have been sacked but if he said anything",
  "He watched, helpless, as the door closed behind her",
  "The footsteps were moving away",
  "Was it simply luck that had caused her to turn down this street?",
  "As she searched, her movements were frantic",
  "He had enjoyed ten years of being totally irresponsible",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "Without meaning to, she'd arranged two dates for the same evening",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "His voice had never sounded so cold",
  "Why had no-one ever mentioned Mum's twin?",
  "She was a stranger in a very strange land",
  "He had never seen so many homeless people in one place",
  "There was sadness in her face as she told him he looked a lot like his father",
  "He could hear everything, but dare not open his eyes",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "He waited until her husband was out of the room, then...",
  "As she stopped to catch her breath, she looked back",
  "The horse came back alone",
  "Only the very oldest people remembered a time when humans could see in colour",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "As he fell, he waited for",
  "They found his diary under his bed",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "He hadn't seen anything like it in twenty years of teaching",
  "She kept checking her phone and email, wishing someone would make contact",
  "He realised the child had seen him and he couldn't let him get away",
  "He wanted her job and it would be easy enough to",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "Half the names on the list had already been crossed off.",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "She felt for the lock in the dark",
  "He wanted her job, and it would be easy enough to discredit her",
  "Bacon sandwiches always reminded her",
  "It had never entered his head that his son was not his own blood",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "As he fell, he waited for",
  "There was a strange wailing sound coming from the next room",
  "The kiss reminded him of chemistry lessons in school, when if the right two elements were put together, they'd explode",
  "The entrance to the tunnel was his only way out",
  "The accident wasn't her fault",
  "He hadn't seen the old man since",
  "As he opened the car door, there was a loud",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "As he opened the car door, there was a loud",
  "He sat her down and held her close before telling her the terrible news",
  "Most vivid amongst the memories of his home town",
  "She was a stranger in a very strange land",
  "She suddenly realised she might be alone for the rest of her life",
  "He had an hour to get home. If he didn't make it,",
  "As the dream faded, she chased it, forlorn",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "She opened her handbag and tipped the contents onto the floor",
  "They couldn't be allowed to fight. He had to think of a way to stop it",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "As he fell, he waited for",
  "It had never entered his head that his son was not his own blood",
  "They had to work together, so they would need to",
  "The victim had tried to write something as he was dying",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "It was just for one night",
  "As the dream faded, she chased it, forlorn",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "She went to the toilet and on her way back, opened the wrong door",
  "They had been together for twenty-five years",
  "The boy hadn't spoken since his twin had gone",
  "He watched, helpless, as the door closed behind her",
  "She couldn't believe it. Was it really him?",
  "He didn't want to go out on such a night but",
  "She took a deep breath and said to her boss -",
  "As he opened the car door, there was a loud",
  "He waited until her husband was out of the room, then...",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "There had been many theories about how she had been murdered",
  "Without meaning to, she'd arranged two dates for the same evening",
  "She could smell gas even before she opened the door",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "He opened the door to find her standing there, crying.",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "Without meaning to, she'd arranged two dates for the same evening",
  "He had the urge to clear the ground, to look out and see nothing",
  "She was a stranger in a very strange land",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "She could smell gas even before she opened the door",
  "He didn't understand what he'd done to her, but he would by the time she was finished",
  "He hadn't seen the old man since",
  "There was a strange wailing sound coming from the next room",
  "Everything about her was a lie",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "She went to the toilet and on her way back, opened the wrong door",
  "He had enjoyed ten years of being totally irresponsible",
  "She'd only given in because she was lonely",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "To his horror, he realised he'd been this way before",
  "She had to find the necklace, before",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "When she opened the door, she wished",
  "With him, she'd had some wonderfully stable times",
  "The girl shouldn't have been sacked but if he said anything",
  "The house wasn't the same to her any more",
  "The victim had tried to write something as he was dying",
  "It had never entered his head that his son was not his own blood",
  "As soon as she walked in, she felt the tension",
  "There was something not quite right about the window.",
  "She could smell gas even before she opened the door",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "She had to find the necklace, before",
  "She went to the toilet and on her way back, opened the wrong door",
  "He hadn't seen anything like it in twenty years of teaching",
  "To my cheating wife, I leave",
  "More and more people were refusing to obey the laws of the land",
  "His voice had never sounded so cold",
  "She kept checking her phone and email, wishing someone would make contact",
  "Reluctantly, he handed over the key",
  "The entrance to the tunnel was his only way out",
  "The attack was over in seconds",
  "As a child, he'd been told dolls were for girls.",
  "She had made a poor job of hiding the damage",
  "Amongst her mother's possessions, she found",
  "With sadness, she realised they needed some time apart",
  "Why had no-one ever mentioned Mum's twin?",
  "He had the urge to clear the ground, to look out and see nothing",
  "She was a stranger in a very strange land",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "So far, the disease had cropped up in five different towns",
  "Why had no-one ever mentioned Mum's twin?",
  "There was something not quite right about the window.",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "They had to work together, so they would need to",
  "She went to the toilet and on her way back, opened the wrong door",
  "Why had no-one ever mentioned Mum's twin?",
  "Was it simply luck that had caused her to turn down this street?",
  "He hadn't seen anything like it in twenty years of teaching",
  "They had been together for twenty-five years",
  "The little boy's idea of heaven was",
  "She went to the toilet and on her way back, opened the wrong door",
  "Please try to persuade him to come, for my sake",
  "He opened the door to find her standing there, crying.",
  "The girl shouldn't have been sacked but if he said anything",
  "The footsteps were moving away",
  "She had made a poor job of hiding the damage",
  "When he lifted his head, she barely recognised him for the bruises",
  "She kept checking her phone and email, wishing someone would make contact",
  "She opened her handbag and tipped the contents onto the floor",
  "She'd got him wrong, but that was her loss",
  "It was just for one night",
  "Why had no-one ever mentioned Mum's twin?",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "She had made a poor job of hiding the damage",
  "The entrance to the tunnel was his only way out",
  "He realised the child had seen him and he couldn't let him get away  ",
  "He kept absolutely still as the footsteps got louder",
  "Was it a knock that had woken her",
  "More and more people were refusing to obey the laws of the land",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "She'd got him wrong, but that was her loss",
  "He wanted to tell him how much he loved him but",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "She suddenly realised she might be alone for the rest of her life",
  "The attack was over in seconds",
  "She turned and nearly fell over the bonnet of his car, which was crawling quietly along the street.",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "He didn't understand what he'd done to her, but he would by the time she was finished",
  "The pen hadn't been worth stealing",
  "The footsteps were moving away",
  "He was going back to a place he'd hoped he would never see again",
  "She felt for the lock in the dark",
  "The pink glove lay on the ground, almost covered",
  "She had followed the woman for days and at last her patience was paying off",
  "She had missed the last train and there was only one person she",
  "The attack was over in seconds",
  "As the policeman pulled back the sheet, she knew immediately that",
  "She looked around quickly, to see if anything had been taken.",
  "So far, the disease had cropped up in five different towns",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "The boy hadn't spoken since his twin had gone",
  "She decided to go to her father's grave, to ask his advice",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "As he fell, he waited for",
  "The Ferrari stopped and the tinted window opened to reveal",
  "The pink glove lay on the ground, almost covered",
  "He wanted to tell him how much he loved him but",
  "Someone must have seen him, because the police were at his door",
  "The pen hadn't been worth stealing",
  "As he opened the car door, there was a loud",
  "He wanted her job, and it would be easy enough to discredit her",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "She opened her handbag and tipped the contents onto the floor",
  "He knew he must keep very still while he waited",
  "The fire was getting closer",
  "They had been together for twenty-five years",
  "Who would have poisoned the old man's dog?",
  "She went to the toilet and on her way back, opened the wrong door",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "There was something not quite right about the window.",
  "There was nothing left of the money except",
  "She decided to go to her father's grave, to ask his advice",
  "There was something not quite right about the window.",
  "As she stopped to catch her breath, she looked back",
  "She clung on to the piece of driftwood, praying for daylight",
  "The entrance to the tunnel was his only way out",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "She kept checking her phone and email, wishing someone would make contact",
  "They had to work together so they were going to have to learn to get along",
  "She could smell gas even before she opened the door",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "He was going back to a place he'd hoped he would never see again",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "With him, she'd had some wonderfully stable times",
  "She could smell gas even before she opened the door",
  "She went to the toilet and on her way back, opened the wrong door",
  "He hadn't seen anything like it in twenty years of teaching",
  "She felt for the lock in the dark",
  "He wanted her job and it would be easy enough to",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "There was nothing left of the money except",
  "The kiss reminded him of chemistry lessons in school, when if the right two elements were put together, they'd explode",
  "As he fell, he waited for",
  "There was nothing left of the money except",
  "He watched, helpless, as the door closed behind her",
  "Someone must have seen him, because the police were at his door",
  "She took a deep breath and said to her boss -",
  "As he fell, he waited for",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "He knew he must keep very still while he waited",
  "He'd had a bad day and just needed something to make him feel better",
  "He realised the child had seen him and he couldn't let him get away",
  "The text message simply said 'very clever'",
  "They couldn't be allowed to fight. He had to think of a way to stop it",
  "The Ferrari stopped and the tinted window opened to reveal",
  "When she opened the door, she wished",
  "There was something not quite right about the window.",
  "They found his diary under his bed",
  "He had enjoyed ten years of being totally irresponsible",
  "He didn't want to go out on such a night but",
  "He had waited twenty years to return it",
  "There had been many theories about how she had been murdered",
  "He wanted her job, and it would be easy enough to discredit her",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "As soon as she walked in, she felt the tension",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "When he lifted his head, she barely recognised him for the bruises",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "Without meaning to, she'd arranged two dates for the same evening",
  "He wanted her job and it would be easy enough to",
  "She had missed the last train and there was only one person she",
  "Only the very oldest people remembered a time when humans could see in colour",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "He had the urge to clear the ground, to look out and see nothing",
  "That summer seemed to last forever",
  "He wanted her job and it would be easy enough to",
  "She stood out from the crowd because",
  "He knew he must keep very still while he waited",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "He opened the door to find her standing there, crying.",
  "She turned and nearly fell over the bonnet of his car, which was crawling quietly along the street.",
  "There was a strange wailing sound coming from the next room",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "He sat her down and held her close before telling her the terrible news",
  "He wanted her job and it would be easy enough to",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "After five years, he just happened to be walking down her street?",
  "He hadn't seen anything like it in twenty years of teaching",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "He was going back to a place he'd hoped he would never see again",
  "Someone must have seen him, because the police were at his door",
  "The entrance to the tunnel was his only way out",
  "Half the names on the list had already been crossed off.",
  "She was carried along by the crowd",
  "As she searched, her movements were frantic",
  "He was excited to find 150 new emails in his inbox",
  "The footsteps were moving away",
  "The victim had tried to write something as he was dying",
  "With sadness, she realised they needed some time apart",
  "She had to find the necklace, before",
  "The horse came back alone",
  "She could smell gas even before she opened the door",
  "She had missed the last train and there was only one person she",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "He hadn't seen anything like it in twenty years of teaching",
  "It was time. She'd fought against it for so long",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "She was carried along by the crowd",
  "There was something not quite right about the window.",
  "With sadness, she realised they needed some time apart",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "The little boy's idea of heaven was",
  "She was carried along by the crowd",
  "She'd got him wrong, but that was her loss",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "The fire was getting closer",
  "More and more people were refusing to obey the laws of the land",
  "The whole family had been cursed since",
  "As the dream faded, she chased it, forlorn",
  "His voice had never sounded so cold",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "Was it a knock that had woken her?",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "The footsteps were moving away",
  "They had to work together, so they would need to",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "Why had no-one ever mentioned Mum's twin?",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "To save his own life, he would have to kill",
  "She had followed the woman for days and at last her patience was paying off",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "It was up to her to investigate how the accident had really happened",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "She could smell gas even before she opened the door",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "Most vivid amongst the memories of his home town",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "He wanted her job and it would be easy enough to",
  "The victim had tried to write something as he was dying",
  "He'd had a bad day and just needed something to make him feel better",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "The pink glove lay on the ground, almost covered",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "She went to the toilet and on her way back, opened the wrong door",
  "As the dream faded, she chased it, forlorn",
  "Reluctantly, he handed over the key",
  "Only the very oldest people remembered a time when humans could see in colour",
  "As she stopped to catch her breath, she looked back",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "The boy hadn't spoken since his twin had gone",
  "They found his diary under his bed",
  "The attack was over in seconds",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "He'd had a bad day and just needed something to make him feel better",
  "The Ferrari stopped and the tinted window opened to reveal",
  "The horse came back alone",
  "To save his own life, he would have to kill",
  "He had the urge to clear the ground, to look out and see nothing",
  "She had to find the necklace, before",
  "As a child, he'd been told dolls were for girls.",
  "They had to make sure that none of their colleagues noticed",
  "He was older than she'd thought he would be",
  "She felt for the lock in the dark",
  "More and more people were refusing to obey the laws of the land",
  "That summer seemed to last forever",
  "He sat her down and held her close before telling her the terrible news",
  "She clung on to the piece of driftwood, praying for daylight",
  "He wanted to tell him how much he loved him but",
  "They had to work together so they were going to have to learn to get along",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "It had never entered his head that his son was not his own blood",
  "Everything about her was a lie",
  "She was carried along by the crowd",
  "The little boy's idea of heaven was",
  "As she stopped to catch her breath, she looked back",
  "She'd only given in because she was lonely",
  "They had to work together, so they would need to",
  "He waited until her husband was out of the room, then...",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "As he opened the car door, there was a loud",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "He realised the child had seen him and he couldn't let him get away",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "He realised the child had seen him and he couldn't let him get away",
  "He could hear everything, but dare not open his eyes",
  "Without meaning to, she'd arranged two dates for the same evening",
  "The horse came back alone",
  "With sadness, she realised they needed some time apart",
  "She had to find the necklace, before",
  "She stood out from the crowd because",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "She felt for the lock in the dark",
  "She couldn't believe it. Was it really him?",
  "The house wasn't the same to her any more",
  "He wanted her job, and it would be easy enough to discredit her",
  "That summer seemed to last forever",
  "Amongst her mother's possessions, she found",
  "As she searched, her movements were frantic",
  "To save his own life, he would have to kill",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "To save his own life, he would have to kill",
  "He could hear everything, but dare not open his eyes",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "The first Christmas she could remember was also her best Christmas ever.",
  "He was going back to a place he'd hoped he would never see again",
  "She'd only given in because she was lonely",
  "Why had no-one ever mentioned Mum's twin?",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "He knew he must keep very still while he waited",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "It would only be a fling - she wasn't about to break up the happy home",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "He realised the child had seen him and he couldn't let him get away",
  "She'd got him wrong, but that was her loss",
  "The victim had tried to write something as he was dying",
  "Was it a knock that had woken her?",
  "It was the trip of a lifetime, yet",
  "It had never entered his head that his son was not his own blood",
  "Half the names on the list had already been crossed off.",
  "With him, she'd had some wonderfully stable times",
  "As she stopped to catch her breath, she looked back",
  "It was up to her to investigate how the accident had really happened",
  "The Ferrari stopped and the tinted window opened to reveal",
  "Someone must have seen him, because the police were at his door",
  "She took a deep breath and said to her boss -",
  "She had missed the last train and there was only one person she",
  "The house wasn't the same to her any more",
  "She kept checking her phone and email, wishing someone would make contact",
  "He opened the door to find her standing there, crying.",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "Please try to persuade him to come, for my sake",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "She had followed the woman for days and at last her patience was paying off",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "It was the trip of a lifetime, yet",
  "She looked around quickly, to see if anything had been taken.",
  "When he lifted his head, she barely recognised him for the bruises",
  "He had never seen so many homeless people in one place",
  "With sadness, she realised they needed some time apart",
  "He realised the child had seen him and he couldn't let him get away",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "She took a deep breath and said to her boss -",
  "Half the names on the list had already been crossed off.",
  "There was nothing left of the money except",
  "He was older than she'd thought he would be",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "With sadness, she realised they needed some time apart",
  "There was something not quite right about the window.",
  "She looked around quickly, to see if anything had been taken.",
  "She opened her handbag and tipped the contents onto the floor",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "They had been together for twenty-five years",
  "The pen hadn't been worth stealing",
  "She could smell gas even before she opened the door",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "She had no intention of choosing sides until she was sure who was going to win",
  "The girl shouldn't have been sacked but if he said anything",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "The Ferrari stopped and the tinted window opened to reveal",
  "There was nothing left of the money except",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "There had been many theories about how she had been murdered",
  "Half the names on the list had already been crossed off.",
  "He wanted her job, and it would be easy enough to discredit her",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "As a child, he'd been told dolls were for girls.",
  "So far, the disease had cropped up in five different towns",
  "She'd got him wrong, but that was her loss",
  "She opened her handbag and tipped the contents onto the floor",
  "He'd had a bad day and just needed something to make him feel better",
  "She was a stranger in a very strange land",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "There was sadness in her face as she told him he looked a lot like his father",
  "He didn't want to go out on such a night but",
  "With sadness, she realised they needed some time apart",
  "Most vivid amongst the memories of his home town",
  "With sadness, she realised they needed some time apart",
  "Someone must have seen him, because the police were at his door",
  "Everything about her was a lie",
  "As a child, he'd been told dolls were for girls.",
  "The pink glove lay on the ground, almost covered",
  "The pen hadn't been worth stealing",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "He'd had a bad day and just needed something to make him feel better",
  "He had an hour to get home. If he didn't make it,",
  "The accident wasn't her fault",
  "As she stopped to catch her breath, she looked back",
  "The horse came back alone",
  "There was nothing left of the money except",
  "As soon as she walked in, she felt the tension",
  "As he fell, he waited for",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "The horse came back alone",
  "She had made a poor job of hiding the damage",
  "She'd got him wrong, but that was her loss",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "He had the urge to clear the ground, to look out and see nothing",
  "He kept absolutely still as the footsteps got louder",
  "As the dream faded, she chased it, forlorn",
  "He hadn't meant to scare the child",
  "The first Christmas she could remember was also her best Christmas ever.",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "He was terrified of small spaces and she knew",
  "He watched, helpless, as the door closed behind her",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "To my cheating wife, I leave",
  "The pink glove lay on the ground, almost covered",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "More and more people were refusing to obey the laws of the land",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "There was a strange wailing sound coming from the next room",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "He had an hour to get home. If he didn't make it,",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "His voice had neShe wasn't happy about it, but she'd been recalled to life for a reason",
  "ver sounded so cold",
  "He hadn't seen the old man since",
  "The attack was over in seconds",
  "He sat her down and held her close before telling her the terrible news",
  "He knew he must keep very still while he waited",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "She had no intention of choosing sides until she was sure who was going to win",
  "Without meaning to, she'd arranged two dates for the same evening",
  "He was terrified of small spaces and she knew",
  "To his horror, he realised he'd been this way before",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "She had no intention of choosing sides until she was sure who was going to win",
  "He was terrified of small spaces and she knew",
  "The kiss reminded him of chemistry lessons in school, when if the right two elements were put together, they'd explode",
  "It was up to her to investigate how the accident had really happened",
  "With sadness, she realised they needed some time apart",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "He had the urge to clear the ground, to look out and see nothing",
  "The fire was getting closer",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "He wanted to tell him how much he loved him but",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "The accident wasn't her fault",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "Why had no-one ever mentioned Mum's twin?",
  "He waited until her husband was out of the room, then...",
  "When she opened the door, she wished",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "When he lifted his head, she barely recognised him for the bruises",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "It was just for one night",
  "He sat her down and held her close before telling her the terrible news",
  "As soon as she walked in, she felt the tension",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "He didn't want to go out on such a night but",
  "He wanted her job, and it would be easy enough to discredit her",
  "The entrance to the tunnel was his only way out",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "Reluctantly, he handed over the key",
  "He was older than she'd thought he would be",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "That summer seemed to last forever",
  "To my cheating wife, I leave",
  "When she opened the door, she wished",
  "He had enjoyed ten years of being totally irresponsible",
  "It started with a chance meeting on a film-set",
  "Please try to persuade him to come, for my sake",
  "The girl shouldn't have been sacked but if he said         anything   ",
  "The boy hadn't spoken since his twin had gone",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "As she searched, her movements were frantic",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "She had missed the last train and there was only one person she",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "He hadn't seen the old man since",
  "She had made a poor job of hiding the damage",
  "The whole family had been cursed since",
  "The fire was getting closer",
  "She went to the toilet and on her way back, opened the wrong door",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "As the policeman pulled back the sheet, she knew immediately that",
  "The text message simply said 'very clever'",
  "More and more people were refusing to obey the laws of the land",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "Only the very oldest people remembered a time when humans could see in colour",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "They found his diary under his bed",
  "She went to the toilet and on her way back, opened the wrong door",
  "She had to find the necklace, before",
  "She wasn't happy about it, but she'd been recalled to life for a reason",
  "Reluctantly, he handed over the key",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "The little boy's idea of heaven was",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "She kept checking her phone and email, wishing someone would make contact",
  "Please try to persuade him to come, for my sake",
  "She had no intention of choosing sides until she was sure who was going to win",
  "She took a deep breath and said to her boss -",
  "She went to the toilet and on her way back, opened the wrong door",
  "He knew he must keep very still while he waited",
  "It would only be a fling - she wasn't about to break up the happy home",
  "He could hear everything, but dare not open his eyes",
  "Without meaning to, she'd arranged two dates for the same evening",
  "Who would have poisoned the old man's dog?",
  "The whole family had been cursed since",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "He realised the child had seen him and he couldn't let him get away",
  "He could hear everything, but dare not open his eyes",
  "'This is what we do with the horses,' she said as she sponged her mother's hot face, dripping water everywhere",
  "There was something not quite right about the window.",
  "Amongst her mother's possessions, she found",
  "The text message simply said 'very clever'",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "The little boy's idea of heaven was",
  "She looked around quickly, to see if anything had been taken.",
  "It was the trip of a lifetime, yet",
  "He was excited to find 150 new emails in his inbox",
  "Who would have poisoned the old man's dog?",
  "They had to work together so they were going to have to learn to get along",
  "He realised the child had seen him and he couldn't let him get away",
  "There was a legend about the well in the garden",
  "Everything about her was a lie",
  "She decided to go to her father's grave, to ask his advice",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "She could smell gas even before she opened the door",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "The fire was getting closer",
  "Without meaning to, she'd arranged two dates for the same evening",
  "The Ferrari stopped and the tinted window opened to reveal",
  "When he lifted his head, she barely recognised him for the bruises",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "They had to work together so they were going to have to learn to get along",
  "With him, she'd had some wonderfully stable times",
  "The entrance to the tunnel was his only way out",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "Was it simply luck that had caused her to turn down this street?",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "He wanted to tell him how much he loved him but",
  "To his horror, he realised he'd been this way before",
  "She suddenly realised she might be alone for the rest of her life",
  "As she stopped to catch her breath, she looked back",
  "They had been together for twenty-five years",
  "There was something not quite right about the window.",
  "He opened the door to find her standing there, crying.",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "She couldn't believe it. Was it really him?",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "She'd got him wrong, but that was her loss",
  "As the dream faded, she chased it, forlorn",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "It had never entered his head that his son was not his own blood",
  "Please try to persuade him to come, for my sake",
  "He opened the door to find her standing there, crying.",
  "There was sadness in her face as she told him he looked a lot like his father",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "He was excited to find 150 new emails in his inbox",
  "She had no intention of choosing sides until she was sure who was going to win",
  "The girl shouldn't have been sacked but if he said anything",
  "As he fell, he waited for",
  "He wanted her job and it would be easy enough to",
  "She had made a poor job of hiding the damage",
  "The boy hadn't spoken since his twin had gone",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "The footsteps were moving away",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "Reluctantly, he handed over the key",
  "The sound of laughter drifted up from the street below, making him feel very alone in this new town",
  "The little boy's idea of heaven was",
  "She had no intention of choosing sides until she was sure who was going to win",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "He wanted her job and it would be easy enough to",
  "The first Christmas she could remember was also her best Christmas ever.",
  "As the dream faded, she chased it, forlorn",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "He could hear everything, but dare not open his eyes",
  "The little boy's idea of heaven was",
  "It would only be a fling - she wasn't about to break up the happy home",
  "She had made a poor job of hiding the damage",
  "The pen hadn't been worth stealing",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "It would only be a fling - she wasn't about to break up the happy home",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "Half the names on the list had already been crossed off.",
  "As a child, he'd been told dolls were for girls.",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "Why had no-one ever mentioned Mum's twin?",
  "When he lifted his head, she barely recognised him for the bruises",
  "They had to make sure that none of their colleagues noticed",
  "He could hear everything, but dare not open his eyes",
  "She turned and nearly fell over the bonnet of his car, which was crawling quietly along the street.",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "Reluctantly, he handed over the key",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "It started with a chance meeting on a film-set",
  "He sat her down and held her close before telling her the terrible news",
  "He hadn't seen anything like it in twenty years of teaching",
  "The footsteps were moving away",
  "She decided to go to her father's grave, to ask his advice",
  "The house wasn't the same to her any more",
  "He realised the child had seen him and he couldn't let him get away",
  "He hadn't seen the old man since",
  "He was excited to find 150 new emails in his inbox",
  "It was time. She'd fought against it for so long",
  "It had never entered his head that his son was not his own blood",
  "She looked around quickly, to see if anything had been taken.",
  "To my cheating wife, I leave",
  "He had never seen so many homeless people in one place",
  "The whole family had been cursed since",
  "The fire was getting closer",
  "More and more people were refusing to obey the laws of the land",
  "There was a strange wailing sound coming from the next room",
  "He was going back to a place he'd hoped he would never see again",
  "She couldn't believe it. Was it really him?",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "It had never entered his head that his son was not his own blood",
  "He had an hour to get home. If he didn't make it,",
  "There was nothing left of the money except",
  "The entrance to the tunnel was his only way out",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "Only the very oldest people remembered a time when humans could see in colour",
  "The horse came back alone",
  "The accident wasn't her fault",
  "The fire was getting closer",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "The girl shouldn't have been sacked but if he said anything",
  "He had never seen so many homeless people in one place",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "As she stopped to catch her breath, she looked back",
  "That summer seemed to last forever",
  "He wanted her job and it would be easy enough to",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "It was time. She'd fought against it for so long",
  "When he lifted his head, she barely recognised him for the bruises",
  "Everything about her was a lie",
  "As soon as she walked in, she felt the tension",
  "They had to work together so they were going to have to learn to get along",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "As she searched, her movements were frantic",
  "Half the names on the list had already been crossed off.",
  "It would only be a fling - she wasn't about to break up the happy home",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "Who would have poisoned the old man's dog?",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "The horse came back alone",
  "She had no intention of choosing sides until she was sure who was going to win",
  "The horse came back alone",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "They had been together for twenty-five years",
  "She had made a poor job of hiding the damage",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "He could hear everything, but dare not open his eyes",
  "The accident wasn't her fault",
  "Without meaning to, she'd arranged two dates for the same evening",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "He'd had a bad day and just needed something to make him feel better",
  "Without meaning to, she'd arranged two dates for the same evening",
  "There was sadness in her face as she told him he looked a lot like his father",
  "They had to work together so they were going to have to learn to get along",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "The footsteps were moving away",
  "He hadn't seen the old man since",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "She suddenly realised she might be alone for the rest of her life",
  "The boy hadn't spoken since his twin had gone",
  "The whole family had been cursed since",
  "He had an hour to get home. If he didn't make it,",
  "She couldn't believe it. Was it really him?",
  "She had followed the woman for days and at last her patience was paying off",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "She had followed the woman for days and at last her patience was paying off",
  "They had been together for twenty-five years",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "She went to the toilet and on her way back, opened the wrong door",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "He was older than she'd thought he would be",
  "He didn't want to go out on such a night but",
  "As the dream faded, she chased it, forlorn",
  "That summer seemed to last forever",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "The little boy's idea of heaven was",
  "She was carried along by the crowd",
  "He was excited to find 150 new emails in his inbox",
  "There was nothing left of the money except",
  "As a child, he'd been told dolls were for girls.",
  "She stood out from the crowd because",
  "He wanted to tell him how much he loved him but",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "There had been many theories about how she had been murdered",
  "There had been many theories about how she had been murdered",
  "To save his own life, he would have to kill",
  "The girl shouldn't have been sacked but if he said anything",
  "The attack was over in seconds",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "He was excited to find 150 new emails in his inbox",
  "The pen hadn't been worth stealing",
  "She'd only given in because she was lonely",
  "He had the urge to clear the ground, to look out and see nothing",
  "He was going back to a place he'd hoped he would never see again",
  "The boy hadn't spoken since his twin had gone",
  "It started with a chance meeting on a film-set",
  "It was just for one night",
  "She'd got him wrong, but that was her loss",
  "The sound of laughter drifted up from the street below, making him feel very alone in this new town",
  "It was just for one night",
  "The woman doting on her children across the room was still recognisable as the bully from her high school",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "It was time. She'd fought against it for so long",
  "As he opened the car door, there was a loud",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "To my cheating wife, I leave",
  "As she stopped to catch her breath, she looked back",
  "She had missed the last train and there was only one person she",
  "With him, she'd had some wonderfully stable times",
  "There was a legend about the well in the garden",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "When she opened the door, she wished",
  "The pink glove lay on the ground, almost covered",
  "He wanted her job and it would be easy enough to",
  "The footsteps were moving away",
  "He'd had a bad day and just needed something to make him feel better",
  "He had waited twenty years to return it",
  "She took a deep breath and said to her boss -",
  "The first Christmas she could remember was also her best Christmas ever.",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "Was it a knock that had woken her?",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "With sadness, she realised they needed some time apart",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "They had to make sure that none of their colleagues noticed",
  "She had no intention of choosing sides until she was sure who was going to win",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "More and more people were refusing to obey the laws of the land",
  "There was nothing left of the money except",
  "He hadn't seen anything like it in twenty years of teaching",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "To save his own life, he would have to kill",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "He kept absolutely still as the footsteps got louder",
  "With sadness, she realised they needed some time apart",
  "They had to work together, so they would need to",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "She looked around quickly, to see if anything had been taken.",
  "The first Christmas she could remember was also her best Christmas ever.",
  "The kiss reminded him of chemistry lessons in school, when if the right two elements were put together, they'd explode",
  "They had to work together, so they would need to",
  "To his horror, he realised he'd been this way before",
  "His voice had never sounded so cold",
  "She had no intention of choosing sides until she was sure who was going to win",
  "Without meaning to, she'd arranged two dates for the same evening",
  "She opened her handbag and tipped the contents onto the floor",
  "The horse came back alone",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "She had missed the last train and there was only one person she",
  "Most vivid amongst the memories of his home town",
  "There was a strange wailing sound coming from the next room",
  "He hadn't meant to scare the child",
  "Head high, she swept out of the room. A second later, head even higher, she swept back in, snatched up the money and was gone again.",
  "He hadn't seen anything like it in twenty years of teaching",
  "Everything about her was a lie",
  "They had to work together so they were going to have to learn to get along",
  "Half the names on the list had already been crossed off.",
  "He had the urge to clear the ground, to look out and see nothing",
  "He had an hour to get home. If he didn't make it,",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "He hadn't seen anything like it in twenty years of teaching",
  "To his horror, he realised he'd been this way before",
  "When he lifted his head, she barely recognised him for the bruises",
  "The first Christmas she could remember was also her best Christmas ever.",
  "That summer seemed to last forever",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "He hadn't seen the old man since",
  "The first Christmas she could remember was also her best Christmas ever.",
  "Everything about her was a lie",
  "Why shouldn't they help themselves, after the way they'd been treated?",
  "She kept checking her phone and email, wishing someone would make contact",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "It was time. She'd fought against it for so long",
  "They had to work together so they were going to have to learn to get along",
  "The urge to interrupt him before he had finished was overwhelming",
  "She was a stranger in a very strange land",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "With sadness, she realised they needed some time apart",
  "He waited until her husband was out of the room, then...",
  "The boy hadn't spoken since his twin had gone",
  "The boy hadn't spoken since his twin had gone",
  "Most vivid amongst the memories of his home town",
  "Why had no-one ever mentioned Mum's twin?",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "They had to work together so they were going to have to learn to get along",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "He didn't reply and immediately she thought: they've got him and now they're coming for me",
  "She took a deep breath and said to her boss -",
  "He didn't understand what he'd done to her, but he would by the time she was finished",
  "He wanted her job and it would be easy enough to",
  "He had waited twenty years to return it",
  "He realised the child had seen him and he couldn't let him get away",
  "The house wasn't the same to her any more",
  "With sadness, she realised they needed some time apart",
  "As soon as she walked in, she felt the tension",
  "He hadn't seen anything like it in twenty years of teaching",
  "There was a legend about the well in the garden",
  "There was something not quite right about the window.",
  "The boy hadn't spoken since his twin had gone",
  "When he lifted his head, she barely recognised him for the bruises",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "To his horror, he realised he'd been this way before",
  "He was older than she'd thought he would be",
  "She turned and nearly fell over the bonnet of his car, which was crawling quietly along the street.",
  "The sound of laughter drifted up from the street below, making him feel very alone in this new town",
  "Without meaning to, she'd arranged two dates for the same evening",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "She felt for the lock in the dark",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "As the policeman pulled back the sheet, she knew immediately that",
  "It would only be a fling - she wasn't about to break up the happy home",
  "The pink glove lay on the ground, almost covered",
  "His solution to the problem of having two children to care for, was to find another woman to do the job",
  "He hadn't seen the old man since",
  "She stood out from the crowd because",
  "They had been together for twenty-five years",
  "Was it a knock that had woken her?",
  "The house wasn't the same to her any more",
  "She looked around quickly, to see if anything had been taken.",
  "He was going back to a place he'd hoped he would never see again",
  "The Ferrari stopped and the tinted window opened to reveal",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "She decided to go to her father's grave, to ask his advice",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "She had followed the woman for days and at last her patience was paying off",
  "There was a legend about the well in the garden",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "Please try to persuade him to come, for my sake",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "After five years, he just happened to be walking down her street?",
  "He was successful, attractive and charming but he'd learnt that people of that age always had secrets",
  "There was nothing left of the money except",
  "He waited until her husband was out of the room, then...",
  "They couldn't be allowed to fight. He had to think of a way to stop it",
  "He was older than she'd thought he would be",
  "He was excited to find 150 new emails in his inbox",
  "She sat in the chair, staring into space, while the kids charged around above her, yelling at the tops of their voices",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "The pink glove lay on the ground, almost covered",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "She opened her handbag and tipped the contents onto the floor",
  "There was nothing left of the money except",
  "It had never entered his head that his son was not his own blood",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "There was sadness in her face as she told him he looked a lot like his father",
  "He hadn't meant to scare the child",
  "She had no intention of choosing sides until she was sure who was going to win",
  "It started with a chance meeting on a film-set",
  "Bacon sandwiches always reminded her",
  "They had to work together, so they would need to",
  "When she opened the door, she wished",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "She went to the toilet and on her way back, opened the wrong door",
  "It was up to her to investigate how the accident had really happened",
  "He was terrified of small spaces and she knew",
  "The pen hadn't been worth stealing",
  "As the dream faded, she chased it, forlorn",
  "He was at a crossroads and whichever path he chose would ruin someone''s life",
  "He wanted her job and it would be easy enough to",
  "He didn't understand what he'd done to her, but he would by the time she was finished",
  "Half the names on the list had already been crossed off.",
  "Who would have poisoned the old man's dog?",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "She stood out from the crowd because",
  "He realised the child had seen him and he couldn't let him get away",
  "She opened her handbag and tipped the contents onto the floor",
  "They'd only been apart for a week and already he had a new lover hanging off his arm",
  "His voice had never sounded so cold",
  "He opened the door to find her standing there, crying.",
  "She decided to go to her father's grave, to ask his advice",
  "She decided to go to her father's grave, to ask his advice",
  "It started with a chance meeting on a film-set",
  "He realised the child had seen him and he couldn't let him get away",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "She decided to go to her father's grave, to ask his advice",
  "He was going back to a place he'd hoped he would never see again",
  "She could smell gas even before she opened the door",
  "Only the very oldest people remembered a time when humans could see in colour",
  "He didn't understand what he'd done to her, but he would by the time she was finished",
  "Reluctantly, he handed over the key",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "The urge to interrupt him before he had finished was overwhelming",
  "She was beginning to realise how far down in her memory she'd buried her teenage years",
  "There had been many theories about how she had been murdered",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "As he fell, he waited for",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "The fire was getting closer",
  "The first Christmas she could remember was also her best Christmas ever.",
  "So far, the disease had cropped up in five different towns",
  "Without meaning to, she'd arranged two dates for the same evening",
  "The horse came back alone",
  "The door was closed, and as I put my hand on the handle, I felt afraid of what we might find",
  "The first Christmas she could remember was also her best Christmas ever.",
  "He knew he must keep very still while he waited",
  "The little boy's idea of heaven was",
  "He had never seen so many homeless people in one place",
  "She'd only given in because she was lonely",
  "The sound of laughter drifted up from the street below, making him feel very alone in this new town",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "Half the names on the list had already been crossed off.",
  "Was it a knock that had woken her?",
  "The urge to interrupt him before he had finished was overwhelming",
  "He had an hour to get home. If he didn't make it,",
  "He stared hard at the table as he tried to recall his attacker, and began describing",
  "He kept absolutely still as the footsteps got louder",
  "As he opened the car door, there was a loud",
  "She'd only given in because she was lonely",
  "He knew he must keep very still while he waited",
  "There was a legend about the well in the garden",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "He had the urge to clear the ground, to look out and see nothing",
  "Only the very oldest people remembered a time when humans could see in colour",
  "As she searched, her movements were frantic",
  "She had made a poor job of hiding the damage",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "As she stopped to catch her breath, she looked back",
  "The old photos made her conscious of her age, of how much time had passed - and of what an interesting life she'd had.",
  "She had found something that would mean she'd never be poor again - but there was a catch",
  "There was a strange wailing sound coming from the next room",
  "Someone must have seen him, because the police were at his door",
  "On the Livingstone estate, flies were sometimes the first indication that someone had died",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "More and more people were refusing to obey the laws of the land",
  "There was a legend about the well in the garden",
  "As soon as she walked in, she felt the tension",
  "He opened the door to find her standing there, crying.",
  "With him, she'd had some wonderfully stable times",
  "So far, the disease had cropped up in five different towns",
  "To his horror, he realised he'd been this way before",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "He hadn't seen the old man since",
  "His hiding place had been discovered. What on earth was he going to do now?",
  "She took a deep breath and said to her boss -",
  "She had made a poor job of hiding the damage",
  "She decided to go to her father's grave, to ask his advice",
  "As he took in the view from the twentieth floor, the lights went out all over the city",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "He wanted her job and it would be easy enough to",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "She had made a poor job of hiding the damage",
  "The first Christmas she could remember was also her best Christmas ever.",
  "He kept absolutely still as the footsteps got louder",
  "As she stopped to catch her breath, she looked back",
  "The best way to cut the woman down to size would be to flirt with her husband",
  "There had been many theories about how she had been murdered",
  "That summer seemed to last forever",
  "She decided to go to her father's grave, to ask his advice",
  "He was going back to a place he'd hoped he would never see again",
  "He had kept their mother alive in their thoughts. Too alive, perhaps.",
  "He wanted to tell him how much he loved him but",
  "He opened the door to find her standing there, crying.",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "It was time. She'd fought against it for so long",
  "\"It's all bills, bills, bills. That's my only answer now,\" he said, pointing to",
  "He watched, helpless, as the door closed behind her",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "As the prison gate closed behind him, he saw someone waiting for him",
  "Who would have poisoned the old man's dog?",
  "He had an hour to get home. If he didn't make it,",
  "With sadness, she realised they needed some time apart",
  "She looked around quickly, to see if anything had been taken.",
  "The fire was getting closer",
  "They had to work together so they were going to have to learn to get along",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "It was time. She'd fought against it for so long",
  "It started with a chance meeting on a film-set",
  "He hadn't seen the old man since",
  "She had followed the woman for days and at last her patience was paying off",
  "They had to make sure that none of their colleagues noticed",
  "She had made a poor job of hiding the damage",
  "He waited until her husband was out of the room, then...",
  "She had no intention of choosing sides until she was sure who was going to win",
  "The first Christmas she could remember was also her best Christmas ever.",
  "Outside the cabin, the wind howled through the trees, while inside, the old woman's fire was nearly out.",
  "As a child, he'd been told dolls were for girls.",
  "He was stunned - the stranger in front of him looked exactly like the girl he'd been dreaming about",
  "Why had no-one ever mentioned Mum's twin?",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "The old house, with its wildly overgrown garden, was silent, secretive",
  "The footsteps were moving away",
  "He realised the child had seen him and he couldn't let him get away",
  "The house wasn't the same to her any more",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "She decided to go to her father's grave, to ask his advice",
  "As he fell, he waited for",
  "As the policeman pulled back the sheet, she knew immediately that",
  "Spider threads caught at her face, a sign that no-one had been there yet",
  "When she opened the door, she wished",
  "She had made a poor job of hiding the damage",
  "She knelt on the carpet in her new living-room, a big cardboard box in front of her, unwrapping ornaments",
  "It was the trip of a lifetime, yet",
  "She yelled down at her boys who were arguing over the one swing left in the tiny playground",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "After five years, he just happened to be walking down her street?",
  "Under normal circumstances he would speak his mind, but, with a gun against his head",
  "He looked at his phone, turned pale, then quickly left the room. She watched him, smiling.",
  "She decided to go to her father's grave, to ask his advice",
  "She clung on to the piece of driftwood, praying for daylight",
  "Bacon sandwiches always reminded her",
  "As he flicked through the letters, a small, handwritten envelope caught his attention and his heart began to thump",
  "Most vivid amongst the memories of his home town",
  "He wanted her job and it would be easy enough to",
  "When he lifted his head, she barely recognised him for the bruises",
  "More and more people were refusing to obey the laws of the land",
  "He knew he must keep very still while he waited",
  "As she searched, her movements were frantic",
  "He wanted to tell him how much he loved him but",
  "When she opened the door, she wished",
  "When her father was drunk, he'd say 'I used to have a brother, you know', and get a faraway look in his eyes",
  "That summer seemed to last forever",
  "The pen hadn't been worth stealing",
  '"It\'s so lovely to meet you!" It was said in a way that immediately made him feel special, the long-lost family member',
  "They had been together for twenty-five years",
  "When he lifted his head, she barely recognised him for the bruises",
  "The pen hadn't been worth stealing",
  "With her faded dress and worn shoes, she knew she couldn't go",
  "She went to the toilet and on her way back, opened the wrong door",
  "They had to work together, so they would need to",
  "There was something not quite right about the window.",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "She had made a poor job of hiding the damage",
  "He had waited twenty years to return it",
  "Was it a knock that had woken her?",
  "She took a deep breath and said to her boss -",
  "They say everyone who looks into their family history will find a secret sooner or later",
  "It had never entered his head that his son was not his own blood",
  "They'd had a lot of freedom back then and not just because their environment was safer - few children had been allowed to roam as much as they",
  "The old house, with its wildly overgrown garden, was silent, secretive"
];