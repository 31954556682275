export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    darkBackground: "#1A2026",
    background: "#FFF",
    darkgray: "#7a7777",
    specialRed: "#F45653",
    textColor: "#e2e3e3",
    darkText: "#51565D",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {
    mainflex: {
      overflowY: "auto",
      bg: "white",
      padding: "0",
      margin: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: ["column", "column", "row"],
    },
  },
  text: {},
  buttons: {
    primary: {
      color: "specialRed",
      bg: "Transparent",
      border: "1px solid",
      borderColor: "specialRed",
      borderRadius: "0.3em",
      fontFamily: "Lato",
    },
    secondary: {
      color: "textColor",
      bg: "Transparent",
      p: "0",
      m: "0",
      borderTop: "0px dotted #000",
      borderLeft: "0px dotted #000",
      borderRight: "0px dotted #000",
      borderBottom: "1px dotted",
      borderBottomColor: "textColor",
      fontFamily: "Lato",
    },
    tertiary: {
      color: "textColor",
      bg: "Transparent",
      p: "0",
      m: "0",
      mx: "5px",
      border: "1px solid",
      borderColor: "specialRed",
      ":hover": { bg: "specialRed", color: "white" },
      width: "75px",
      fontFamily: "Lato",
      outlineWidth: "0px",
    },
    circular: {
      color: "textColor",
      bg: "Transparent",
      p: "0",
      m: "0",
      width: "3.0em",
      height: "3.0em",
      borderRadius: "1.5em",
      fontFamily: "Lato",
      outlineWidth: "0px",
    },
  },
  forms: {
    input: {
      color: "primary",
    },
    select: {
      borderRadius: 9999,
    },
    label: {},
    radio: {},
    checkbox: {},
  },
  textarea: {
    border: "none",
    overflow: "auto",
    outline: "none",
    "-webkit-box-shadow": "none",
    "-moz-box-shadow": "none",
    boxShadow: "0px 0px 0px green",
    resize: "none",
    p: "10px",
  },
};
