import React from "react";
import { Text } from "rebass";

export default function (props) {
  return (
    <Text
      as="span"
      color="black"
      textAlign="left"
      py="10px"
      sx={{
        fontSize: props.footer
          ? ["0.6em", "0.7em", "0.9em"]
          : ["0.7em", "0.8em", "1em"],
        fontFamily: "Lato",
        fontWeight: "400",
        ...props.sy,
      }}
      {...props}
    >
      <Text as="span" fontWeight="700">
        {props.title}
      </Text>
      {props.content}
    </Text>
  );
}
