export const MODES = {
  time: "time",
  words: "words",
};

export const PASS_TEXT = [
  "You are amazing!",
  "Great work!",
  "Wow!",
  "Perfect!",
  "Keep coming here!",
  "Let's try the stealth mode!",
  "You did it!",
  "We love you!",
  "Keep writing!",
  "You can be a pro!",
];

export const levels = [
  {
    min: 0,
    max: 2500,
    name: "Rookie",
    description:
      "It seems like you have just started writing here Best of luck reaching your next level!",
  },
  {
    min: 2500,
    max: 7500,
    name: "Beginner",
    description:
      "This is amazing, you are doing great as a beginner, keep writing everyday to reach your next level",
  },
  {
    min: 7500,
    max: 15000,
    name: "Pro",
    description:
      "Pro sounds great right! You are best at this! The daily dose of writing has done you good!s",
  },
  {
    min: 15000,
    max: 20000,
    name: "Expert",
    description:
      "I can't believe you! Seems like you have taken this seriously, writer's block must be nowhere near you by now!",
  },
  {
    min: 20000,
    max: 30000,
    name: "Ninja",
    description:
      "This is insane! you have mastered the art of fighting with the writer's block!",
  },
];

export const lengths = [
  {
    length: 150,
    timeOut: 1000,
    hp: 250,
  },
  {
    length: 250,
    timeOut: 1000,
    hp: 500,
  },
  {
    length: 500,
    timeOut: 1000,
    hp: 1000,
  },
  {
    length: 750,
    timeOut: 1000,
    hp: 1500,
  },
  {
    length: 1667,
    timeOut: 1000,
    hp: 3500,
  },
];

export const time = [
  {
    duration: 3,
    timeOut: 1000,
    hp: 250,
  },
  {
    duration: 5,
    timeOut: 1000,
    hp: 500,
  },
  {
    duration: 10,
    timeOut: 1000,
    hp: 1000,
  },
  {
    duration: 15,
    timeOut: 1000,
    hp: 1500,
  },
  {
    duration: 30,
    timeOut: 1000,
    hp: 2500,
  },
  {
    duration: 60,
    timeOut: 1000,
    hp: 3500,
  },
];

//settting hp from SpeedWriting
export function setHP(hp) {
  try {
    let userHP = JSON.parse(localStorage.getItem("userHP"));
    let nextHP = userHP + hp;

    localStorage.setItem("userHP", JSON.stringify(nextHP));
  } catch (e) {
    let nextHP = hp;
    localStorage.setItem("userHP", JSON.stringify(nextHP));
  }
}

//getting for stats value is duration and mode is type
export function getHP(mode, value) {
  if (mode === MODES.time) {
    return time.filter((t) => t.duration === value)[0].hp;
  } else {
    return lengths.filter((t) => t.length === value)[0].hp;
  }
}

export function getLevel() {
  let userHP = 0;

  try {
    userHP = JSON.parse(localStorage.getItem("userHP"));
  } catch (e) {
    userHP = 0;
  }
  let level = levels.filter((l) => userHP >= l.min && userHP < l.max);

  if (userHP < 0) {
    level = [levels[0]];
  }

  if (userHP >= 30000) {
    let returnValue = {
      ...levels[4],
      hp: userHP,
    };

    return returnValue;
  } else {
    level[0].hp = userHP;
    let returnValue = {
      ...level[0],
      hp: userHP,
    };

    return returnValue;
  }
}

export function getStats(data) {
  let wordCount = 0;
  let passed = 0;
  let total = 0;
  let failed = 0;
  data.forEach((item) => {
    let words = item.text.split(" ").length;
    wordCount += words;
    if (item.passed) {
      passed = passed + 1;
    }
    total = total + 1;
  });
  failed = total - passed;
  let level = getLevel();

  return {
    wordCount,
    passed,
    failed,
    level,
  };
}
