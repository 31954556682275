import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "rebass";
import Theme from "./theme/Theme";
import { ThemeProvider } from "emotion-theming";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import { replaceText } from "./stateManager/store";
import { useHistory } from "react-router-dom";
import CustomButton from "./components/Button";
import getParameterByName from "./util/getParamByName";

const Passed = "Yay! Horray!";
const Failed = "Oopsie doop!";
const passedDescription =
  "Congrats! You did it! Time to continue the challenge.";
const failedDescription = "Aww! Don't be sad. You can always try again.";
const Download = "Download";
const Resume = "Resume";
const tryAgain = "Try Again";

function NewApp() {
  const history = useHistory();
  const [passed] = useState(
    getParameterByName("passed", window.location) === "true"
  );
  const [type] = useState(
    getParameterByName("type", window.location) === "true"
  );
  const [number] = useState(
    parseInt(getParameterByName("number", window.location))
  );
  const text = useSelector((state) => state.text);
  const dispatch = useDispatch();

  useEffect(() => {
    if (history.action === "POP") {
      history.push("/");
    }
  }, [history]);

  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <ThemeProvider theme={Theme}>
      <Flex
        width={1}
        height="100vh"
        bg={passed ? "white" : "specialRed"}
        color={passed ? "black" : "white"}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box sx={{ fontFamily: "Lato", fontSize: "50px", fontWeight: "700" }}>
          {passed ? Passed : Failed}
        </Box>
        <Text sx={{ fontFamily: "Lato", fontSize: "20px", fontWeight: "700" }}>
          {passed ? passedDescription : failedDescription}
        </Text>
        <Flex flexDirection="row" justifyContent="center" mt="50px">
          <CustomButton
            data-test-id="resume-try"
            onClick={() => {
              passed
                ? history.push(
                    type
                      ? {
                          pathname: "/speed",
                          search: "?type=" + type + "&number=" + number,
                          state: { authorize: true },
                        }
                      : {
                          pathname: "/speed",
                          search: "?type=" + type + "&number=" + number,
                          state: { authorize: true, continued: true },
                        }
                  )
                : history.push({
                    pathname: "/speed",
                    search:
                      "?type=" +
                      getParameterByName("type", window.location) +
                      "&number=" +
                      getParameterByName("number", window.location),
                    state: { authorize: true },
                  });
            }}
            content={passed ? Resume : tryAgain}
            passed={passed}
          ></CustomButton>
          {passed ? (
            <CustomButton
              onClick={() => downloadTxtFile()}
              content={Download}
              passed={passed}
            ></CustomButton>
          ) : (
            ""
          )}
        </Flex>
        <CustomButton
          data-test-id="back-to-main"
          onClick={() => {
            dispatch(replaceText(""));
            history.push("/");
          }}
          my="30px"
          content="Go Home"
          passed={passed}
        ></CustomButton>
      </Flex>
    </ThemeProvider>
  );
}

export default NewApp;
