import React from "react";
import "./App.css";
import MainPage from "./MainPage";
import SpeedWriting from "./SpeedWriting";
import NewApp from "./ExitPage";
import Stats from "./Stats";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Privacy from "./Privacy";

function App() {
  return (
    <Router>
      <Route path="/" exact component={MainPage} />
      <Route path="/reset" component={NewApp} />
      <Route path="/speed" component={SpeedWriting} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/stats" component={Stats} />
    </Router>
  );
}

export default App;
