import React, { useState, useEffect, useRef } from "react";
import { Box, Flex } from "rebass";
import { Textarea } from "@rebass/forms";
import { ThemeProvider } from "emotion-theming";
import Theme from "./theme/Theme";
import "./SpeedWriting.css";
import "./App.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import { replaceText } from "./stateManager/store";
import { useHistory } from "react-router-dom";
import { PROMPTS } from "./util/prompts";
import { NightIcon } from "./Icons";
import getParameterByName from "./util/getParamByName";
import { setHP, getHP } from "./util/challenge";

let timeOut = undefined;
let animationTimeOut = undefined;
let Incomplete = true;
let animation = false;

function SpeedWriting() {
  const history = useHistory();

  const [type] = useState(
    getParameterByName("type", window.location) === "true"
  );
  const [minutes, setMinutes] = useState(0);
  const [totalWords] = useState(getParameterByName("number", window.location));
  const [prompt] = useState(getParameterByName("prompt", window.location));
  const [curTime, setCurTime] = useState(0);
  const [words, setWords] = useState(0);
  const [nightmode, setNightmode] = useState(false);
  const [continued, setContinued] = useState(
    history.location.state.continued ? true : false
  );
  const [subtrahend, setSubtrahend] = useState(0);
  const nameInput = useRef(null);

  const text = useSelector((state) => state.text);
  const dispatch = useDispatch();

  //this type can be changed to something more erababela and easy
  const sendToNextPage = (passed) => {
    if (type) {
      history.push({
        pathname: "/reset",
        search: `?type=${type}&number=${
          process.env.REACT_APP_MODE === "test"
            ? parseInt(getParameterByName("number", window.location))
            : minutes
        }&passed=${passed}`,
        state: { authorize: true },
      });
    } else {
      history.push({
        pathname: "/reset",
        search: "?type=" + type + "&number=" + totalWords + "&passed=" + passed,
        state: { authorize: true },
      });
    }
  };

  const countWords = (str) => {
    return str.trim().split(/\s+/).length;
  };

  const CheckType = () => {
    if (type) {
      if (minutes === 0) {
        if (getParameterByName("number", window.location) === "") {
          setMinutes(5);
        } else {
          setMinutes(parseInt(getParameterByName("number", window.location)));
        }
        checkTesting();
      }
    } else {
      setCurTime((words / totalWords) * 100);
    }
  };

  const checkTesting = () => {
    if (process.env.REACT_APP_MODE === "test") {
      setMinutes(0.05);
    }
  };

  const checkCompletion = () => {
    if (curTime > 100) {
      Incomplete = false;
      dispatch(replaceText(nameInput.current.value));
      saveStats(nameInput.current.value, true);
      let hp = getHP(
        type ? "time" : "words",
        type
          ? process.env.REACT_APP_MODE === "test"
            ? parseInt(getParameterByName("number", window.location))
            : minutes
          : parseInt(totalWords)
      );
      setHP(hp);
      sendToNextPage(true);
    }
  };

  const saveStats = (text, passed) => {
    let tempStat = {
      text: text,
      duration: type ? minutes + " minutes" : totalWords + " words",
      passed,
      words: words,
      date: new Date(Date.now()).toDateString(),
      prompt: prompt ? PROMPTS[prompt] : "",
    };

    if (process.env.REACT_APP_MODE === "test" && type) {
      tempStat.duration =
        parseInt(getParameterByName("number", window.location)) + " minutes";
      console.log("duration set ", tempStat.duration, tempStat);
    }

    let stats = localStorage.getItem("stats");
    if (stats) {
      let dummyStats = JSON.parse(stats);
      dummyStats.push(tempStat);
      localStorage.setItem("stats", JSON.stringify(dummyStats));
    } else {
      let newStats = [];
      newStats.push(tempStat);
      localStorage.setItem("stats", JSON.stringify(newStats));
    }
  };

  const ClearAnimations = () => {
    if (timeOut !== undefined) {
      clearTimeout(timeOut);
      clearTimeout(animationTimeOut);
    }
    if (animation) {
      document.getElementById("mainFlex").classList.remove("flex-anim");
      animation = false;
    }
  };

  const setAnimationAndTimeOut = () => {
    if (Incomplete) {
      animationTimeOut = setTimeout(() => {
        if (document.getElementById("mainFlex") !== null) {
          document.getElementById("mainFlex").classList.add("flex-anim");
          animation = true;
        }
      }, 2000);

      timeOut = setTimeout(() => {
        saveStats("", false);
        sendToNextPage(false);
      }, 5000);
    }
  };

  useEffect(() => {
    return function () {
      clearTimeout(animationTimeOut);
      clearTimeout(timeOut);
      timeOut = undefined;
      animationTimeOut = undefined;
      Incomplete = true;
      animation = false;
    };
  }, []);

  useEffect(() => {
    if (type) {
      const interval = setInterval(() => {
        var oneMoment = curTime + 100 / (minutes * 60 * 4);

        if (minutes === 0) {
          oneMoment = 0;
        } else if (oneMoment > 100) {
          Incomplete = false;
        }

        setCurTime(oneMoment);
      }, 250);

      return () => {
        clearInterval(interval);
      };
    }
  }, [minutes, curTime, type]);

  useEffect(() => {
    if (nameInput.current) {
      nameInput.current.focus();
      prompt
        ? (nameInput.current.value = PROMPTS[prompt])
        : (nameInput.current.value = text);
    }
  }, [nameInput, prompt, text]);

  useEffect(() => {
    if (history.action === "POP") {
      history.push("/");
      console.log("POP POP POP POP history");
    }
  }, [history]);
  return (
    <ThemeProvider theme={Theme}>
      <Flex
        width={1}
        height="100%"
        flexDirection="column"
        alignItems="center"
        id="mainFlex"
        bg={nightmode ? "darkBackground" : "background"}
      >
        <Flex flexDirection="row" alignSelf="flex-end" p="20px">
          <NightIcon
            size="32"
            color="darkText"
            onClick={() => setNightmode(!nightmode)}
          />
        </Flex>
        <Flex
          height="10vh"
          width="25%"
          flexDirection="row"
          alignSelf="flex-end"
          pt="5px"
        >
          <CircularProgressbar
            value={curTime}
            styles={buildStyles({
              pathColor: "#F45653",
              textColor: "#F45653",
              trailColor: "#EBECF0",
            })}
          />
        </Flex>
        <Textarea
          data-test-id="main-text"
          ref={nameInput}
          color={nightmode ? "white" : "black"}
          mt="0em"
          overflowY="auto"
          height="75vh"
          placeholder="Start Writing..."
          spellCheck={false}
          onChange={() => {
            ClearAnimations();
            CheckType();
            checkCompletion();
            if (continued) {
              setSubtrahend(countWords(nameInput.current.value));
              setContinued(false);
              setWords(0);
            } else {
              setWords(countWords(nameInput.current.value) - subtrahend);
            }
            setAnimationAndTimeOut();
          }}
          sx={{
            width: ["300px", "600px", "800px"],
            fontFamily: "Lato",
            fontSize: "20px",
          }}
        ></Textarea>
        <Box
          color="#858383"
          sx={{
            width: ["300px", "600px", "800px"],
            fontFamily: "Lato",
            fontSize: "20px",
          }}
          data-test-id="total-words"
        >
          {words} Words
        </Box>
      </Flex>
    </ThemeProvider>
  );
}

export default SpeedWriting;
