import React, { useEffect, useState } from "react";
import "./App.css";
import { ThemeProvider } from "emotion-theming";
import Theme from "./theme/Theme";
import { Flex, Image, Text } from "rebass";
import Options from "./components/WritingOptions";
import eventEmitter from "./events";
import { useHistory } from "react-router-dom";
import { replaceText } from "./stateManager/store";
import CustomButton from "./components/Button";
import randomIntFromInterval from "./util/Random";
import { useDispatch } from "react-redux";

function MainPage() {
  const history = useHistory();
  const [type, setType] = useState(true);
  const [number, setNumber] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    eventEmitter.addListener("fromNote", (params) => {
      setType(params.color);
      setNumber(params.item);
    });

    window.onpopstate = (e) => {
      dispatch(replaceText(""));
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={Theme}>
      <Flex
        overflow="auto"
        bg="white"
        flexDirection={["column", "column", "row"]}
        height="100%"
        sx={{ position: "relative" }}
      >
        <CustomButton
          data-test-id="my-challenges"
          m="10px"
          content="My Challenges"
          type="primary"
          sy={{
            position: "absolute",
            top: "0px",
            right: "0px",
            ":hover": {
              bg: "background",
              color: "specialRed",
              fontSize: "17px",
            },
            borderColor: "background",
          }}
          onClick={() => history.push("/stats")}
        ></CustomButton>
        <Flex
          width={["100vw", "100vw", "50vw"]}
          justifyContent="center"
          flexShrink="0"
          alignItems="center"
          flexDirection="column"
        >
          <Flex flexDirection="column">
            <Image
              src={process.env.PUBLIC_URL + "/appLogo.svg"}
              sx={{
                height: ["5em", "8em", "11em"],
              }}
            ></Image>

            <Text
              as="span"
              color="darkText"
              textAlign="center"
              mt={50}
              sx={{
                fontSize: ["2em", "2.5em", "3.5em"],
                fontFamily: "Lato",
                fontWeight: "900",
              }}
            >
              {"CALL OF "}
              <Text as="span" color="specialRed">
                {"WRITING"}
              </Text>
              <Text fontSize="20px" fontWeight="400">
                by Streetwriters
              </Text>
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          width={["100vw", "100vw", "50vw"]}
          flexShrink="0"
          alignItems="center"
          justifyContent="center"
        >
          <Flex flexDirection="column" alignItems="center">
            <Text
              display="flex"
              color="darkText"
              mb={10}
              mt={[20, 20, 0]}
              textAlign="center"
              sx={{
                fontSize: ["1.1em", "1.1em", "1.5em"],
                fontFamily: "Lato",
              }}
            >
              Stop whining, start writing!
            </Text>
            <Options />
            <CustomButton
              data-test-id="start-writing"
              type="primary"
              width="250px"
              fontSize={["1em", "1em", "1.3em"]}
              px="1.5em"
              py="0.6em"
              onClick={() => {
                history.push({
                  pathname: "/speed",
                  search: `?type=${type}&number=${number}`,
                  state: { authorize: true },
                });
              }}
              content="Start Writing"
            ></CustomButton>
            <CustomButton
              data-test-id="start-writing-prompt"
              type="primary"
              mt="3em"
              px="1em"
              height="2.75em"
              width="200px"
              fontSize={["0.7em", "0.7em", "0.9em"]}
              onClick={() => {
                history.push({
                  pathname: "/speed",
                  search:
                    "?type=" +
                    type +
                    "&number=" +
                    number +
                    "&prompt=" +
                    randomIntFromInterval(0, 1373),
                  state: { authorize: true },
                });
              }}
              content="Generate Prompt"
            ></CustomButton>
            <Flex mt={30}>
              <Text
                as="a"
                href="https://patreon.com/streetwriters"
                target="_blank"
                fontFamily="Lato"
                color="gray"
              >
                Support on Patreon
              </Text>
              <Text
                as="a"
                href="/privacy"
                // onClick={() => {
                //   history.push({
                //     pathname: "/privacy",
                //   });
                // }}
                fontFamily="Lato"
                color="gray"
                ml={2}
              >
                Privacy
              </Text>
            </Flex>
          </Flex>
          <Flex
            flexDirection={["column", "column", "row"]}
            alignItems="center"
            mb="10px"
          >
            <Image
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.streetwriters.callofwriting"
                );
              }}
              height="75px"
              src={process.env.PUBLIC_URL + "/google-play-badge.png"}
            />
            <Image
              onClick={() => {
                window.open("https://apps.apple.com/app/id1536533658");
              }}
              height="53px"
              src={process.env.PUBLIC_URL + "/app-store.png"}
            />
          </Flex>
        </Flex>
      </Flex>
    </ThemeProvider>
  );
}

export default MainPage;

/*
 
*/
