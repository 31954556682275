import { createStore } from "redux";

//actions
function replaceText(value) {
  return {
    type: "REPLACE",
    text: value,
  };
}

export { replaceText };

const initialText = {
  text: "",
};

//reducers
const writtenText = (state = initialText, action) => {
  switch (action.type) {
    case "REPLACE":
      return {
        text: action.text,
      };
    case "CLEAR":
      return {
        text: "",
      };
    default:
      return state;
  }
};

export default createStore(writtenText);
